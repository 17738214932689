import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  Typography,
  Paper,
  Grid,
  Divider,
  Chip,
  CircularProgress,
  Box,
  Link,
  Tooltip,
  Button
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import config from '../config';
import './Detail.css';
import SEO from './common/SEO';
import { StudyStrengthIndicator, EffectBars } from './Research';
import { fetchConditions } from '../Utils';


const ArticleDetail = ({ conditions, supplements }) => {
  
  const { articleId, supplementKey, conditionKey } = useParams();
  
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      try {
        await fetchArticle();
      } catch (error) {
        setError(error);
      }
    };
    loadData();
    window.scrollTo(0, 0);
  }, [articleId]);

  const fetchArticle = async () => {
    setLoading(true);
    setError(null);
    try {
      const queryParams = new URLSearchParams({
        mode: 'single',
        article_ref: articleId
      });

      const response = await fetch(
        `${config.EDGE_FUNCTION_URL}/getResearchArticles?${queryParams}`
      );

      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 429) {
          throw new Error('You\'ve reached your daily reading limit. Consider creating an account for unlimited access to our research database.');
        }
        throw new Error('Unable to load article. Please try again later.');
      }

      const result = await response.json();
      if (result.data && Array.isArray(result.data) && result.data.length > 0) {
        setArticle(result.data[0]);
      } else if (result.data && !Array.isArray(result.data)) {
        setArticle(result.data);
      } else {
        throw new Error('Article not found');
      }

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const generateStructuredData = () => {
    if (!article) return null;
    const headline = article.ai_title_simplified || article.title;
    if (!headline) return null;

    const structuredData = {
      "@context": "https://schema.org",
      "@type": "ScholarlyArticle",
      "headline": `${headline} | myStack Research`,
      "name": headline,
      "datePublished": article.date_published || new Date().toISOString(),
      "publisher": {
        "@type": "Organization",
        "name": article.journal_title || "myStack"
      },
      "description": generateDescription(),
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": `${window.location.origin}/${supplementKey ? 'supplements' : 'conditions'}/${supplementKey || conditionKey}/research/${articleId}`
      }
    };

    if (article.first_author_name) {
      structuredData.author = [{
        "@type": "Person",
        "name": article.first_author_name,
        ...(article.first_author_affiliation && {
          "affiliation": {
            "@type": "Organization",
            "name": article.first_author_affiliation
          }
        })
      }];
    }

    if (Array.isArray(article.ai_effects)) {
      structuredData.keywords = article.ai_effects.join(", ");
    }

    if (article.ai_subject_condition) {
      structuredData.about = [{
        "@type": "MedicalCondition",
        "name": article.ai_subject_condition
      }];
    }

    return structuredData;
  };

  const generateDescription = () => {
    if (!article) return '';
    const description = article.ai_abstract_summary || article.abstract;
    if (!description) return '';
    const cleanDescription = description.replace(/<[^>]*>/g, '');
    let truncated = cleanDescription.substring(0, 155);
    const lastPeriod = truncated.lastIndexOf('.');
    if (lastPeriod > 120) {
      truncated = truncated.substring(0, lastPeriod + 1);
    } else {
      truncated = truncated.trim() + '...';
    }
    return truncated;
  };

  const renderConditionEffects = () => {
    if (!article?.ai_condition_effects) return null;

    return Object.entries(article.ai_condition_effects).map(([conditionKey, effect]) => {
      const conditionData = conditions.find(c => c.key === conditionKey);
      const conditionName = conditionData?.name || conditionKey
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

      return (
        <Tooltip
          key={conditionKey}
          title={`Impact on ${conditionName}: ${(effect >= 0 ? '+' : '')}${effect.toFixed(2)} out of ±1.0`}
        >
          <Chip
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.75 }}>
                <span>{conditionName}</span>
                <EffectBars effect={effect} />
              </Box>
            }
            size="small"
            sx={{
              backgroundColor: 'transparent',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
              height: '24px',
              py: 0.25,
              '& .MuiChip-label': {
                px: 1,
              }
            }}
          />
        </Tooltip>
      );
    });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    const isRateLimit = error.includes('daily');
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 6, px: 3 }}>
        <Paper 
          elevation={0}
          sx={{ 
            p: 4, 
            textAlign: 'center',
            maxWidth: 600,
            backgroundColor: '#f9f9f9',
            borderRadius: 2
          }}
        >
          <Typography variant="h5" gutterBottom color="text.primary" fontWeight="bold">
            {isRateLimit ? 'Reading Limit Reached' : 'Something Went Wrong'}
          </Typography>
          <Typography color="text.secondary" paragraph>
            {error}
          </Typography>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: isRateLimit ? 'column' : 'row',
            gap: 2,
            justifyContent: 'center',
            mt: 3 
          }}>
            {isRateLimit && (
              <RouterLink to="/login" style={{ textDecoration: 'none' }}>
                <Button variant="contained" color="primary">
                  Log In or Create Account
                </Button>
              </RouterLink>
            )}
            <Button variant="text" color="primary" onClick={() => navigate(-1)}>
              Back to Research Articles
            </Button>
          </Box>
        </Paper>
      </Box>
    );
  }

  if (!article) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 6 }}>
        <Typography variant="h5">Article not found.</Typography>
        <Button variant="text" color="primary" onClick={() => navigate(-1)} sx={{ mt: 2 }}>
          Go Back
        </Button>
      </Box>
    );
  }

  const renderChips = (items = []) => {
    if (!Array.isArray(items)) return null;
    return items.map((item, index) => (
      <Chip
        key={index}
        label={item}
        variant="outlined"
        sx={{ marginRight: 0.5, marginBottom: 0.5 }}
      />
    ));
  };

  const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${year}-${month}`;
  };

  return (
    <>
      <SEO
        title={article?.ai_title_simplified || article?.title}
        description={generateDescription()}
        type="article"
        canonicalUrl={`${window.location.origin}/${supplementKey ? 'supplements' : 'conditions'}/${supplementKey || conditionKey}/research/${articleId}`}
        structuredData={generateStructuredData()}
      />

      <Box sx={{ px: { xs: 2, md: 4 }, maxWidth: 900, mx: 'auto', mt: 4 }}>
     

        {/* Article Header */}
        <Paper
          sx={{
            p: { xs: 3, md: 4 },
            mb: 4,
            borderRadius: 2,
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            backgroundColor: '#ffffff'
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              wordBreak: 'break-word',
              mb: 2
            }}
          >
            {article.title}
          </Typography>

          {article.ai_title_simplified && (
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{
                textAlign: 'center',
                color: 'text.secondary',
                fontStyle: 'italic',
                wordBreak: 'break-word',
                mb: 3
              }}
            >
              {article.ai_title_simplified}
            </Typography>
          )}

          {/* Publication & Study Details */}
          <Grid container spacing={4} sx={{ mb: 4 }}>
            {/* Publication Details */}
            <Grid item xs={12} md={6}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Publication Details
              </Typography>
              <Box sx={{ lineHeight: 1.7 }}>
                {formatDate(article.date_published) && (
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <strong>Published Date:</strong> {formatDate(article.date_published)}
                  </Typography>
                )}
                {article.journal_title && (
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <strong>Journal:</strong> {article.journal_title}
                  </Typography>
                )}
                {article.first_author_name && (
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <strong>First Author:</strong> {article.first_author_name}
                  </Typography>
                )}
                {article.first_author_affiliation && (
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <strong>Affiliation:</strong> {article.first_author_affiliation}
                  </Typography>
                )}
              </Box>
            </Grid>

            {/* Study Details */}
            <Grid item xs={12} md={6}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Study Details
              </Typography>
              <Box sx={{ lineHeight: 1.7 }}>
                {article.ai_study_type && (
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <strong>Study Type:</strong> {article.ai_study_type}
                  </Typography>
                )}
                {(article.ai_number_of_subjects !== null) && (
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <strong>Number of Subjects:</strong> {article.ai_number_of_subjects}
                  </Typography>
                )}
                {article.ai_subject_type && (
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <strong>Subject Type:</strong> {article.ai_subject_type}
                  </Typography>
                )}
                {article.ai_subject_condition && (
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <strong>Subject Condition:</strong> {article.ai_subject_condition}
                  </Typography>
                )}
                {article.ai_confidence_of_outcome && (
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <strong>Confidence of Outcome:</strong> {article.ai_confidence_of_outcome}
                  </Typography>
                )}
                {article.ai_study_country && (
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <strong>Study Country:</strong> {article.ai_study_country}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>

          <Divider sx={{ my: 4 }} />

          {/* Key Findings */}
          {article.ai_abstract_summary && (
            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 'bold', mb: 2 }}
              >
                Key Findings
              </Typography>
              <Typography variant="body1" sx={{ color: 'text.secondary', lineHeight: 1.8 }}>
                {article.ai_abstract_summary}
              </Typography>
            </Box>
          )}

          {/* Metrics and Impact Section */}
          {article.ai_study_strength && (
            <Box
              sx={{ 
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mb: 4,
                p: 2,
                backgroundColor: 'rgba(0, 0, 0, 0.02)',
                borderRadius: 1,
                border: '1px solid rgba(0, 0, 0, 0.06)'
              }}
            >
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
                {/* Study Strength Score */}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.25 }}>
                  <Typography 
                    variant="caption" 
                    sx={{
                      fontWeight: 500,
                      color: 'rgba(0, 0, 0, 0.6)',
                      textTransform: 'uppercase',
                      letterSpacing: '0.5px',
                      fontSize: '10px'
                    }}
                  >
                    Study Strength
                  </Typography>
                  <StudyStrengthIndicator score={article.ai_study_strength?.score || 0} />
                </Box>

                {/* Study Strength Criteria */}
                {article.ai_study_strength?.criteria && 
                 Object.keys(article.ai_study_strength.criteria).length > 0 && (
                  <Box sx={{ flex: 1 }}>
                    <Typography 
                      variant="caption" 
                      sx={{
                        fontWeight: 500,
                        color: 'rgba(0, 0, 0, 0.6)',
                        textTransform: 'uppercase',
                        letterSpacing: '0.5px',
                        fontSize: '10px'
                      }}
                    >
                      Study Quality Criteria
                    </Typography>
                    <Box sx={{ 
                      display: 'flex', 
                      flexWrap: 'wrap', 
                      gap: 0.75, 
                      mt: 0.5 
                    }}>
                      {Object.entries(article.ai_study_strength.criteria)
                        .filter(([_, value]) => value !== null && value !== undefined)
                        .map(([key, value]) => (
                          <Chip
                            key={key}
                            label={
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                <span>{key.split('_')
                                  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                  .join(' ')}</span>
                                <Typography 
                                  component="span" 
                                  sx={{ 
                                    color: value ? 'success.main' : 'error.main',
                                    fontWeight: 'bold',
                                    fontSize: '0.75rem'
                                  }}
                                >
                                  {value ? '✓' : '✗'}
                                </Typography>
                              </Box>
                            }
                            size="small"
                            sx={{
                              backgroundColor: value ? 'rgba(76, 175, 80, 0.08)' : 'rgba(239, 83, 80, 0.08)',
                              border: `1px solid ${value ? 'rgba(76, 175, 80, 0.2)' : 'rgba(239, 83, 80, 0.2)'}`,
                              height: '24px',
                              '& .MuiChip-label': {
                                px: 1,
                                fontSize: '0.75rem'
                              }
                            }}
                          />
                      ))}
                    </Box>
                  </Box>
                )}
              </Box>

              {article?.ai_condition_effects && Object.keys(article.ai_condition_effects).length > 0 && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.25, flex: 1 }}>
                  <Typography 
                    variant="caption" 
                    sx={{
                      fontWeight: 500,
                      color: 'rgba(0, 0, 0, 0.6)',
                      textTransform: 'uppercase',
                      letterSpacing: '0.5px',
                      fontSize: '10px'
                    }}
                  >
                    Impact
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.75 }}>
                    {renderConditionEffects()}
                  </Box>
                </Box>
              )}
            </Box>
          )}

          {/* Notable Effects */}
          {article.ai_effects && article.ai_effects.length > 0 && (
            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 'bold', mb: 2 }}
              >
                Notable Effects
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {renderChips(article.ai_effects)}
              </Box>
            </Box>
          )}
        </Paper>

        {/* Abstract Section */}
        <Paper
          sx={{
            p: { xs: 3, md: 4 },
            mb: 4,
            borderRadius: 2,
            backgroundColor: '#fafafa',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', mb: 3 }}
          >
            Study Abstract
          </Typography>
          
          <Box 
            component="div"
            dangerouslySetInnerHTML={{ __html: article.abstract }}
            sx={{
              fontSize: '0.8rem',
              color: 'text.secondary',
              lineHeight: 1.8,
              // Headers
              '& h1, & h2, & h3, & h4, & h5, & h6': {
                fontSize: '0.9rem',
                fontWeight: 600,
                color: 'text.primary',
                mt: 2.5,
                mb: 1,
                '&:first-of-type': {
                  mt: 0
                }
              },
              // In case there are any paragraphs
              '& p': {
                fontSize: '0.8rem',
                mb: 1.5,
                '&:last-child': {
                  mb: 0
                }
              },
              // Lists
              '& ul, & ol': {
                pl: 2,
                mb: 1.5,
                '& li': {
                  fontSize: '0.7rem',
                  mb: 0.5
                }
              },
              // Tables
              '& table': {
                width: '100%',
                borderCollapse: 'collapse',
                mb: 1.5,
                '& th, & td': {
                  border: '1px solid rgba(0, 0, 0, 0.1)',
                  padding: '8px',
                  fontSize: '0.7rem',
                  textAlign: 'left'
                },
                '& th': {
                  backgroundColor: 'rgba(0, 0, 0, 0.02)',
                  fontWeight: 600
                }
              }
            }}
          />

          {article.link_to_full_text && (
            <Box sx={{ mt: 4, textAlign: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                href={article.link_to_full_text}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Full Text
              </Button>
            </Box>
          )}
        </Paper>
      </Box>
    </>
  );
};

export default ArticleDetail;