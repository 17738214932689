import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  CircularProgress,
  Paper,
  Pagination,
  IconButton,
  Select,
  MenuItem,
  Chip,
  Rating,
  Tooltip,
  FormControl,
  Button
} from '@mui/material';
import config from '../config';
import './Research.css';
import { useParams, Link, useSearchParams, useNavigate } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import ScienceIcon from '@mui/icons-material/Science';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { fetchConditions } from '../Utils';

const EffectBars = ({ effect }) => {
  const totalBars = 5;
  
  // Define color schemes for different scores
  const colorSchemes = {
    1: ['#ef5350'], // Single red bar
    2: ['#ef5350', '#e57373'], // Two red bars
    3: ['#ffeb3b', '#fdd835', '#fbc02d'], // Three yellow bars
    4: ['#a5d6a7', '#81c784', '#66bb6a', '#4caf50'], // Four green bars
    5: ['#a5d6a7', '#81c784', '#66bb6a', '#4caf50', '#2e7d32'] // Five green bars with gradient
  };

  // Get the appropriate color scheme based on the score
  const activeColors = colorSchemes[Math.floor(effect)] || [];
  
  // Get the color of the last active bar for the number
  const lastActiveColor = activeColors[Math.floor(effect) - 1] || '#9e9e9e';

  return (
    <Box sx={{ 
      display: 'flex',
      alignItems: 'center',
      gap: 0.5,
      borderLeft: '1px solid rgba(0,0,0,0.1)',
      paddingLeft: 1,
    }}>
      <Box sx={{ display: 'flex', gap: '2px', alignItems: 'center' }}>
        {[...Array(totalBars)].map((_, index) => {
          const isActive = index < Math.floor(effect);
          
          return (
            <Box
              key={index}
              sx={{
                width: '4px',
                height: '16px',
                borderRadius: '2px',
                backgroundColor: isActive 
                  ? activeColors[index]
                  : 'rgba(0, 0, 0, 0.12)',
                transition: 'background-color 0.2s',
              }}
            />
          );
        })}
      </Box>
      <Typography 
        component="span" 
        sx={{ 
          fontSize: '12px',
          fontWeight: 500,
          color: lastActiveColor,
          ml: 0.5
        }}
      >
        {effect.toFixed(1)}
      </Typography>
    </Box>
  );
};

const StudyStrengthIndicator = ({ score }) => {
  const totalBars = 5;
  const activeScore = score || 0;
  const strengthColor = '#2e7d32';

  return (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center',
      gap: 1,
      backgroundColor: 'rgba(46, 125, 50, 0.04)',
      padding: '6px 12px',
      borderRadius: '8px',
      border: '1px solid rgba(46, 125, 50, 0.1)',
    }}>
      <ScienceIcon 
        sx={{ 
          fontSize: 16,
          color: strengthColor,
          opacity: 0.7
        }} 
      />
      <Box sx={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
        {[...Array(totalBars)].map((_, index) => (
          <Box
            key={index}
            sx={{
              width: '16px',
              height: '4px',
              borderRadius: '2px',
              backgroundColor: index < activeScore 
                ? strengthColor
                : 'rgba(46, 125, 50, 0.12)',
              opacity: index < activeScore 
                ? 0.7 + (index * 0.06)
                : 0.1,
              transition: 'all 0.2s ease',
            }}
          />
        ))}
      </Box>
      <Typography 
        sx={{ 
          fontSize: '11px',
          fontWeight: 500,
          color: strengthColor,
          opacity: 0.8,
          ml: 0.5
        }}
      >
        {activeScore.toFixed(1)}
      </Typography>
    </Box>
  );
};

const Research = ({ itemName, conditions, supplements }) => {
  const { supplementKey, conditionKey } = useParams();
  const [researchArticles, setResearchArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeSearch, setActiveSearch] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0,
    hasNextPage: false,
    hasPreviousPage: false
  });
  const [sortBy, setSortBy] = useState('study_strength');
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // Combined effect to handle URL params and fetching
  useEffect(() => {
    // Check for stored state
    const storedState = sessionStorage.getItem('researchState');
    if (storedState) {
      const state = JSON.parse(storedState);
      
      // Set URL params from stored state
      searchParams.set('page', state.params.page);
      searchParams.set('sortBy', state.params.sortBy || 'study_strength');
      if (state.params.search) {
        searchParams.set('search', state.params.search);
      }
      setSearchParams(searchParams);
      
      // Update component state
      setSortBy(state.params.sortBy || 'study_strength');
      setSearchQuery(state.searchQuery || '');
      setActiveSearch(state.activeSearch || '');
      
      // Fetch with stored parameters
      fetchResearchArticles(
        parseInt(state.params.page),
        state.params.sortBy || 'study_strength',
        state.activeSearch || ''
      ).then(() => {
        // Restore scroll position after content loads
        setTimeout(() => {
          window.scrollTo({
            top: state.scrollPosition,
            behavior: 'instant'
          });
        }, 100);
      });
      
      // Clear stored state
      sessionStorage.removeItem('researchState');
    } else {
      // Normal initialization from URL
      const page = parseInt(searchParams.get('page')) || 1;
      const urlSort = searchParams.get('sortBy') || 'study_strength';
      const urlSearch = searchParams.get('search') || '';

      setSortBy(urlSort);
      setSearchQuery(urlSearch);
      setActiveSearch(urlSearch);
      fetchResearchArticles(page, urlSort, urlSearch);
    }
  }, [supplementKey, conditionKey]);

  // Separate effect for search/sort changes
  useEffect(() => {
    if (activeSearch !== searchParams.get('search') || 
        sortBy !== searchParams.get('sortBy')) {
      // Reset to page 1 only when search or sort changes
      searchParams.set('page', '1');
      searchParams.set('sortBy', sortBy);
      searchParams.set('search', activeSearch);
      setSearchParams(searchParams);
      fetchResearchArticles(1, sortBy, activeSearch);
    }
  }, [activeSearch, sortBy]);

  // Update fetchResearchArticles to return the promise
  const fetchResearchArticles = async (page = 1, currentSort = sortBy, currentSearch = activeSearch) => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({
        mode: 'list',
        page: page.toString(),
        pageSize: pagination.pageSize.toString(),
        sortBy: currentSort
      });

      if (supplementKey) {
        queryParams.append('supplementKey', supplementKey);
      } else if (conditionKey) {
        queryParams.append('conditionKey', conditionKey);
      }

      if (currentSearch) {
        queryParams.append('search', currentSearch);
      }

      const response = await fetch(
        `${config.EDGE_FUNCTION_URL}/getResearchArticles?${queryParams}`,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        // Handle specific error cases
        if (response.status === 416) {
          setResearchArticles([]);
          setPagination({
            page: 1,
            pageSize: 10,
            totalPages: 0,
            totalItems: 0,
            hasNextPage: false,
            hasPreviousPage: false
          });
          return;
        }

        if (response.status === 500) {
          throw new Error('Server error occurred. Please try again later.');
        }

        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.details || `HTTP error! status: ${response.status}`);
      }

      const { data, pagination: paginationData } = await response.json();
      
      if (!data) {
        throw new Error('No data received from server');
      }

      setResearchArticles(data);
      setPagination(paginationData);
    } catch (error) {
      console.error('Error fetching research articles:', error);
      setResearchArticles([]);
      setPagination({
        page: 1,
        pageSize: 10,
        totalPages: 0,
        totalItems: 0,
        hasNextPage: false,
        hasPreviousPage: false
      });
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  // Update handleArticleClick to store all relevant state
  const handleArticleClick = () => {
    const currentState = {
      params: {
        page: searchParams.get('page') || '1',
        sortBy: searchParams.get('sortBy') || 'study_strength',
        search: searchParams.get('search') || '',
      },
      scrollPosition: window.scrollY,
      activeSearch: activeSearch,
      searchQuery: searchQuery
    };
    sessionStorage.setItem('researchState', JSON.stringify(currentState));
  };

  // Restore scroll position when component mounts
  useEffect(() => {
    const savedPosition = sessionStorage.getItem('researchScrollPosition');
    if (savedPosition) {
      setTimeout(() => {
        window.scrollTo({
          top: parseInt(savedPosition, 10),
          behavior: 'instant'
        });
        sessionStorage.removeItem('researchScrollPosition');
      }, 100);
    }
  }, [researchArticles]); // Add researchArticles as dependency to ensure content is loaded

  // Format helpers
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${year}-${month}`;
  };

  const formatAuthorName = (authorName) => {
    if (!authorName || authorName.trim() === '') return '';
    return `${authorName.split(',')[0]} et al.`;
  };

  // Update the format helper to properly capitalize and format the name
  const formatSupplementName = (key) => {
    if (!key) return '';
    return key
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  // Update page change handler
  const handlePageChange = (event, newPage) => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
    fetchResearchArticles(newPage, sortBy, activeSearch);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Update search handler
  const handleSearch = () => {
    if (searchQuery.trim()) {
      // Reset all parameters except the search
      searchParams.forEach((value, key) => {
        if (key !== 'search') {
          searchParams.delete(key);
        }
      });
      searchParams.set('page', '1');
      searchParams.set('sortBy', 'study_strength');
      searchParams.set('search', searchQuery.trim());
      setSearchParams(searchParams);
      
      // Update local state
      setActiveSearch(searchQuery.trim());
      setSortBy('study_strength');
      setPagination(prev => ({ ...prev, page: 1 }));
      
      // Fetch results
      fetchResearchArticles(1, 'study_strength', searchQuery.trim());
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  // Update clear handler
  const handleClear = () => {
    // Reset all parameters
    searchParams.forEach((value, key) => {
      searchParams.delete(key);
    });
    searchParams.set('page', '1');
    searchParams.set('sortBy', 'study_strength');
    setSearchParams(searchParams);
    
    // Update local state
    setSearchQuery('');
    setActiveSearch('');
    setSortBy('study_strength');
    setPagination(prev => ({ ...prev, page: 1 }));
    
    // Fetch results
    fetchResearchArticles(1, 'study_strength', '');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const filteredArticles = researchArticles;

  // Helper to render effect strength
  const renderEffectStrength = (effect) => {
    const strengthIcons = {
      'strong_positive': <TrendingUpIcon color="success" />,
      'moderate_positive': <TrendingUpIcon color="success" sx={{ opacity: 0.7 }} />,
      'weak_positive': <TrendingUpIcon color="success" sx={{ opacity: 0.5 }} />,
      'neutral': <TrendingUpIcon color="action" sx={{ opacity: 0.3 }} />,
      'weak_negative': <TrendingDownIcon color="error" sx={{ opacity: 0.5 }} />,
      'moderate_negative': <TrendingDownIcon color="error" sx={{ opacity: 0.7 }} />,
      'strong_negative': <TrendingDownIcon color="error" />
    };
    return strengthIcons[effect] || null;
  };

  // Update the effect strength helper to include colors
  const getEffectStrength = (effectValue) => {
    if (effectValue >= 0.7) return { type: 'strong_positive', color: '#2e7d32' };
    if (effectValue >= 0.3) return { type: 'moderate_positive', color: '#4caf50' };
    if (effectValue > 0) return { type: 'weak_positive', color: '#81c784' };
    if (effectValue === 0) return { type: 'neutral', color: '#9e9e9e' };
    if (effectValue > -0.3) return { type: 'weak_negative', color: '#ef5350' };
    if (effectValue > -0.7) return { type: 'moderate_negative', color: '#e53935' };
    return { type: 'strong_negative', color: '#c62828' };
  };

  // Add the sorting logic before rendering articles
  const sortedArticles = [...researchArticles].sort((a, b) => {
    if (sortBy === 'study_strength') {
      // Get study strength scores (0-5 range)
      const aScore = a.ai_study_strength?.score || 0;
      const bScore = b.ai_study_strength?.score || 0;
      
      // Get maximum effect sizes (0-5 range)
      const aEffects = a.ai_condition_effects || {};
      const bEffects = b.ai_condition_effects || {};
      const aMaxEffect = Object.values(aEffects).length > 0 ? Math.max(...Object.values(aEffects)) : -1;
      const bMaxEffect = Object.values(bEffects).length > 0 ? Math.max(...Object.values(bEffects)) : -1;
      
      // Combine study strength and effect size for sorting
      // Multiply study strength by 10 to maintain precision but keep as primary sort
      const aValue = (aScore * 10) + ((aMaxEffect + 1) / 10);
      const bValue = (bScore * 10) + ((bMaxEffect + 1) / 10);
      
      return bValue - aValue; // Sort descending
    }
    return 0;
  });

  // Simplified placeholder function using passed itemName
  const getSearchPlaceholder = () => {
    return itemName ? `Search ${itemName} articles...` : "Search articles...";
  };

  // Update the render function for condition effects
  const renderConditionEffects = (article) => {
    if (!article?.ai_condition_effects) return null;

    return Object.entries(article.ai_condition_effects).map(([conditionKey, effect]) => {
      const conditionData = conditions.find(c => c.key === conditionKey);
      const conditionName = conditionData?.name || conditionKey
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

      return (
        <Tooltip
          key={conditionKey}
          title={`Impact on ${conditionName}: ${(effect >= 0 ? '+' : '')}${effect.toFixed(2)} out of ±1.0`}
        >
          <Chip
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.75 }}>
                <span>{conditionName}</span>
                <EffectBars effect={effect} />
              </Box>
            }
            size="small"
            sx={{
              backgroundColor: 'transparent',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
              height: '24px',
              py: 0.25,
              '& .MuiChip-label': {
                px: 1,
              }
            }}
          />
        </Tooltip>
      );
    });
  };

  // Update the search bar styling to be more compact
  return (
    <Box sx={{ 
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      px: { xs: 2, sm: 3 },
    }}>
      <Box sx={{ 
        width: '100%',
        maxWidth: '1200px',
      }}>
        {/* Search and controls section */}
        <Box sx={{ 
          width: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'column', md: 'row' }, // Stack vertically on smaller screens
          alignItems: { xs: 'stretch', md: 'center' }, // Stretch on mobile, center on desktop
          justifyContent: 'space-between',
          gap: { xs: 2, sm: 2, md: 3 }, // Consistent spacing between elements
          mt: 3,
          mb: 2,
        }}>
          {/* Search bar container */}
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            width: { xs: '100%', md: '400px' }, // Full width on mobile, fixed on desktop
            maxWidth: '100%',
            position: 'relative',
            backgroundColor: '#f8f8f8',
            borderRadius: '6px',
            border: '1px solid #e0e0e0',
            height: '36px',
            '&:hover': {
              backgroundColor: '#f0f0f0',
              borderColor: '#d0d0d0',
            },
            '&:focus-within': {
              backgroundColor: '#fff',
              borderColor: '#bdbdbd',
              boxShadow: '0 0 0 1px rgba(0,0,0,0.05)',
            }
          }}>
            <SearchIcon sx={{ 
              ml: 1.25,
              color: 'rgba(0, 0, 0, 0.4)',
              fontSize: '15px'
            }} />
            <input
              type="text"
              placeholder={getSearchPlaceholder()}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
              style={{
                width: '100%',
                border: 'none',
                outline: 'none',
                backgroundColor: 'transparent',
                padding: '0 8px',
                fontSize: '13px',
                height: '36px',
                lineHeight: '36px',
                alignSelf: 'flex-start',
              }}
            />
            {searchQuery && (
              <IconButton
                onClick={handleClear}
                size="small"
                sx={{
                  mr: 0.5,
                  p: 0.5,
                  color: 'rgba(0, 0, 0, 0.4)',
                  '&:hover': {
                    color: 'rgba(0, 0, 0, 0.6)',
                    backgroundColor: 'transparent',
                  }
                }}
              >
                <ClearIcon sx={{ fontSize: '14px' }} />
              </IconButton>
            )}
          </Box>

          {/* Sort options */}
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            width: { xs: '100%', md: 'auto' }, // Full width on mobile, auto on desktop
            justifyContent: { xs: 'flex-start', md: 'flex-end' }, // Align left on mobile, right on desktop
          }}>
            <Typography 
              variant="body2" 
              sx={{ 
                color: 'rgba(0, 0, 0, 0.6)', 
                fontSize: { xs: '11px', sm: '13px' },
                whiteSpace: 'nowrap', // Prevent text wrapping
              }}
            >
              Sort by:
            </Typography>
            <Box sx={{ 
              display: 'flex',
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              borderRadius: '8px',
              padding: '2px',
              border: '1px solid rgba(0, 0, 0, 0.08)',
              width: 'auto', // Let it size to content
              flex: { xs: '0 1 auto', md: 'none' }, // Allow shrinking on mobile but don't grow
            }}>
              <Box
                onClick={() => setSortBy('study_strength')}
                sx={{
                  px: { xs: 1.5, sm: 2 },
                  py: 1,
                  fontSize: { xs: '11px', sm: '13px' },
                  cursor: 'pointer',
                  borderRadius: '6px',
                  backgroundColor: sortBy === 'study_strength' ? '#fff' : 'transparent',
                  color: sortBy === 'study_strength' ? '#2e7d32' : 'rgba(0, 0, 0, 0.6)',
                  fontWeight: sortBy === 'study_strength' ? 500 : 400,
                  transition: 'all 0.2s ease',
                  boxShadow: sortBy === 'study_strength' ? '0 2px 4px rgba(0,0,0,0.08)' : 'none',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                  '&:hover': {
                    backgroundColor: sortBy === 'study_strength' ? '#fff' : 'rgba(0, 0, 0, 0.02)',
                  },
                }}
              >
                <ScienceIcon sx={{ fontSize: { xs: 14, sm: 16 } }} />
                Study Strength
              </Box>
              <Box
                onClick={() => setSortBy('date')}
                sx={{
                  px: { xs: 1.5, sm: 2 },
                  py: 1,
                  fontSize: { xs: '11px', sm: '13px' },
                  cursor: 'pointer',
                  borderRadius: '6px',
                  backgroundColor: sortBy === 'date' ? '#fff' : 'transparent',
                  color: sortBy === 'date' ? '#2e7d32' : 'rgba(0, 0, 0, 0.6)',
                  fontWeight: sortBy === 'date' ? 500 : 400,
                  transition: 'all 0.2s ease',
                  boxShadow: sortBy === 'date' ? '0 2px 4px rgba(0,0,0,0.08)' : 'none',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                  '&:hover': {
                    backgroundColor: sortBy === 'date' ? '#fff' : 'rgba(0, 0, 0, 0.02)',
                  },
                }}
              >
                <CalendarTodayIcon sx={{ fontSize: { xs: 14, sm: 16 } }} />
                Publication Date
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Results count in its own container */}
        {!loading && researchArticles.length > 0 && (
          <Box sx={{ 
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            mb: 0,
            mt: 2,
          }}>
            <Typography 
              variant="body2" 
              color="text.secondary"
            >
              {activeSearch 
                ? `Showing ${((pagination.page - 1) * pagination.pageSize) + 1}-${Math.min(pagination.page * pagination.pageSize, pagination.totalItems)} of ${pagination.totalItems} results`
                : `Showing ${((pagination.page - 1) * pagination.pageSize) + 1}-${Math.min(pagination.page * pagination.pageSize, pagination.totalItems)} of ${pagination.totalItems} articles`}
            </Typography>
          </Box>
        )}

        {/* Articles content */}
        <Box sx={{ 
          width: '100%',
          px: { xs: 0, sm: 0 },
          mt: 1,
        }}>
          {/* Loading, Articles, or No Results */}
          {loading ? (
            <Box sx={{ textAlign: 'center', my: 4 }}>
              <CircularProgress />
            </Box>
          ) : researchArticles.length > 0 ? (
            <Box sx={{ width: '100%' }}>
              {sortedArticles.map((article) => (
                <Paper
                  key={article.id}
                  sx={{
                    marginBottom: 4,
                    padding: { xs: 2, sm: 3 },
                    transition: 'box-shadow 0.3s, transform 0.3s',
                    '&:hover': {
                      boxShadow: 6,
                      transform: 'translateY(-4px)',
                    },
                    width: '100%',
                    mx: { xs: 0, sm: 'auto' },
                    borderRadius: '12px',
                    backgroundColor: '#f9f9f9',
                  }}
                >
                  {/* Title first */}
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    {article.ai_title_simplified}
                  </Typography>

                  {/* Combined Metrics Bar */}
                  <Box sx={{ 
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 2,
                    mb: 2,
                    p: 1.5,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    borderRadius: '6px',
                    border: '1px solid rgba(0, 0, 0, 0.06)',
                  }}>
                    {/* Study Strength Section */}
                    <Box sx={{ 
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 0.25,
                    }}>
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          fontWeight: 500,
                          color: 'rgba(0, 0, 0, 0.6)',
                          textTransform: 'uppercase',
                          letterSpacing: '0.5px',
                          fontSize: '10px',
                          lineHeight: 1,
                        }}
                      >
                        Study Strength
                      </Typography>
                      <StudyStrengthIndicator score={article.ai_study_strength?.score || 0} />
                    </Box>

                    {/* Impact Section - only show if there are effects */}
                    {article.ai_condition_effects && Object.keys(article.ai_condition_effects).length > 0 && (
                      <Box sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 0.25,
                        flex: 1,
                      }}>
                        <Typography 
                          variant="caption" 
                          sx={{ 
                            fontWeight: 500,
                            color: 'rgba(0, 0, 0, 0.6)',
                            textTransform: 'uppercase',
                            letterSpacing: '0.5px',
                            fontSize: '10px',
                            lineHeight: 1,
                          }}
                        >
                          Impact
                        </Typography>
                        <Box sx={{ 
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: 0.75,
                        }}>
                          {renderConditionEffects(article)}
                        </Box>
                      </Box>
                    )}
                  </Box>

                  {/* Original Title */}
                  {article.title && (
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      sx={{ fontStyle: 'italic', marginBottom: 1, marginTop: 2 }}
                    >
                      {article.title}
                    </Typography>
                  )}

                  {/* Publication Details */}
                  <Typography 
                    variant="body2" 
                    color="textSecondary" 
                    sx={{ marginBottom: 2 }}
                  >
                    {formatDate(article.date_published)} • {article.journal_title} •{' '}
                    {formatAuthorName(article.first_author_name)}
                  </Typography>

                  {/* Abstract Summary */}
                  {article.ai_abstract_summary && (
                    <Typography 
                      variant="body2" 
                      color="text.secondary" 
                      sx={{ marginBottom: 2 }}
                    >
                      {article.ai_abstract_summary.length > 300
                        ? article.ai_abstract_summary.substring(0, 300) + '...'
                        : article.ai_abstract_summary}
                    </Typography>
                  )}

                  {/* Read More Link */}
                  <Link
                    to={`/${supplementKey ? 'supplements' : 'conditions'}/${supplementKey || conditionKey}/research/${article.article_ref}`}
                    onClick={handleArticleClick}
                    style={{
                      fontSize: '13px',
                      fontWeight: 500,
                      textDecoration: 'none',
                      color: '#2e7d32',
                      transition: 'all 0.2s ease',
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.color = '#1b5e20';
                      e.target.style.gap = '6px';
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.color = '#2e7d32';
                      e.target.style.gap = '4px';
                    }}
                  >
                    Read more
                    <span style={{ fontSize: '16px' }}>→</span>
                  </Link>
                </Paper>
              ))}
              
              {/* Pagination */}
              {pagination.totalPages > 1 && (
                <Box sx={{ textAlign: 'center', my: 4 }}>
                  <Pagination
                    count={pagination.totalPages}
                    page={pagination.page}
                    onChange={handlePageChange}
                    color="primary"
                    size="large"
                    showFirstButton
                    showLastButton
                  />
                </Box>
              )}
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', my: 4 }}>
              <Typography>
                {activeSearch 
                  ? 'No articles found matching your search.'
                  : 'No research articles available.'}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Research;
export { StudyStrengthIndicator, EffectBars };
