import axios from 'axios';

export const calculateAge = (dateOfBirth) => {
    const dob = new Date(dateOfBirth);
    const diffMs = Date.now() - dob.getTime();
    const ageDt = new Date(diffMs);
    return Math.abs(ageDt.getUTCFullYear() - 1970);
  };

export const fetchConditions = async (config) => {
  try {
    const response = await axios.get(`${config.FIREBASE_STORAGE_BASE_URL}config%2Fconditions.json?alt=media`);
    const conditionsList = response.data;
    return conditionsList;
  } catch (error) {
    console.error('Error fetching conditions:', error);
    return [];
  }
};

export const fetchSupplements = async (config) => {
  try {
    const response = await axios.get(`${config.FIREBASE_STORAGE_BASE_URL}config%2Fsupplements.json?alt=media`);
    const supplementsList = response.data;
    return supplementsList;
  } catch (error) {
    console.error('Error fetching supplements:', error);
    return [];
  }
};