const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Firebase storage URLs
const FIREBASE_STORAGE_BASE_URL_DEV = 'https://firebasestorage.googleapis.com/v0/b/stack-94b04.appspot.com/o/';
const FIREBASE_STORAGE_BASE_URL_PROD = 'https://firebasestorage.googleapis.com/v0/b/mystack-41374.appspot.com/o/';
const FIREBASE_STORAGE_BASE_URL = process.env.REACT_APP_ENVIRONMENT === 'production' ? FIREBASE_STORAGE_BASE_URL_PROD : FIREBASE_STORAGE_BASE_URL_DEV;

// Supabase URL for Edge Functions
const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;

// Edge Function URL based on environment
const getEdgeFunctionUrl = () => {
  return `${SUPABASE_URL}/functions/v1`;
};

const EDGE_FUNCTION_URL = getEdgeFunctionUrl();

const UNITS = {
  mg: 'mg',
  mcg: 'mcg',
  g: 'g',
  IU: 'IU',
  ml: 'ml',
  fl_oz: 'fl oz',
  tbsp: 'tbsp',
  tsp: 'tsp',
  mcg_DFE: 'mcg DFE',
  mg_NE: 'mg NE',
  mcg_RAE: 'mcg RAE',
  mg_alpha_tocopherol: 'mg alpha-tocopherol',
  billion_CFU: 'billion CFU',
  tablet: 'Tablet',
  capsule: 'Capsule' /*,
  chewable_tablet: 'Chewable tablet',
  capsule: 'Capsule',
  softgel: 'Softgel',
  gelcap: 'Gelcap',
  caplet: 'Caplet',
  gummy: 'Gummy',
  powder: 'Powder',
  lozenge: 'Lozenge',
  bar: 'Bar',*/
};

const MAX_REQUESTS = 5; // Maximum number of stack generations for non-logged-in users

export default {
  BASE_URL,
  FIREBASE_STORAGE_BASE_URL,
  SUPABASE_URL,
  EDGE_FUNCTION_URL,
  UNITS,
  MAX_REQUESTS,
};
